import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';

const Reviews = () => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        navigate('/')
    }, []);

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>

        </React.Fragment >
    );
}

export default Reviews;
