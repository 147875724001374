import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import DefaultLayoutWebsite from '../../../layout/DefaultLayoutWebsite';

const { Text } = Typography;

const Terms = () => {
    const { t } = useTranslation('legal');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DefaultLayoutWebsite>

                    <Row justify="center">

                        {/* ////////////////////////////////////////////Left Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                        {/* ////////////////////////////////////////////Centered Content///////////////////////////////////////////////////////// */}
                        <Col xs={22} sm={22} md={18} lg={16} xl={14} >


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                                    <Row justify="center" style={{ marginTop: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>


                                            {/* Subtitle Section */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_title')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 1 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_1j')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 2 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_2a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_2b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 3 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_3a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_3b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 4 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_4a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_4b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_4c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 5 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_5f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 6 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_6a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_6b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_6c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 7 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_7h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_7h1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_7h2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_7h3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_7h4')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_7h5')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 8 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_8a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_8b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_8c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_8d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d4')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d5')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d6')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_8d7')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 9 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_9h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>



                                            {/* Section 10 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_10a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_10b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 11 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_11j')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>



                                            {/* Section 12 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_12a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_12b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_12c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_12c1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_12c2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_12c3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('terms_field_12c4')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 13 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_13a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_13b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_13c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_13d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_13e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 14 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_14j')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>




                                            {/* Section 15 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_15a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_15b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 16 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_16a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_16b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 17 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_17a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_17b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 18 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_18a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_18b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 19 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_19a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_19b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 20 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_20a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_20b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 21 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_21a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_21b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_21c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_21d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 22 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_22a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_22b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 23 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_23a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_23b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('terms_field_23c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>


                                </Col>
                            </Row>
                        </Col>


                        {/* ////////////////////////////////////////////Right Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                    </Row>


                </DefaultLayoutWebsite >
            </Col >
        </Row >
    );
}

export default Terms;
