import {
  API_BASE_URL, APP_BASE_URL, FRONT_BASE_URL, MAP_SUGGESTIONS_URL, MAP_URL, MAP_URL_PLACE_NAME, STRIPE_PUB_KEY, GOOGLE_TAG_MANAGER_KEY,
  EXTERNAL_RESOURCE_TO_GET_COUNTRY
} from "../../../config/Request";


/**
 * This object holds the App related global constants.
 *
 * @type object
 */
export const AppConstants = {
  app_code: 'astro',
  app_name: 'Astro Guide',
  app_base_url: APP_BASE_URL,
  api_base_url: API_BASE_URL,
  front_base_url: FRONT_BASE_URL,
  map_suggesion_url: MAP_SUGGESTIONS_URL,
  map_url: MAP_URL,
  map_url_place_name: MAP_URL_PLACE_NAME,
  external_resource_to_get_country: EXTERNAL_RESOURCE_TO_GET_COUNTRY,
  stripe_pub_key: STRIPE_PUB_KEY + "",
  google_tag_manager_key: GOOGLE_TAG_MANAGER_KEY,
};
