import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import DefaultLayoutWebsite from '../../../layout/DefaultLayoutWebsite';
import { Col, Row, Typography } from 'antd';
import { AppConstants } from '../../../helpers/commons/constants';

const { Text } = Typography;

const Privacy = () => {
    const { t } = useTranslation('legal');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DefaultLayoutWebsite>

                    <Row justify="center">

                        {/* ////////////////////////////////////////////Left Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                        {/* ////////////////////////////////////////////Centered Content///////////////////////////////////////////////////////// */}
                        <Col xs={22} sm={22} md={18} lg={16} xl={14} >


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                                    <Row justify="center" style={{ marginTop: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>


                                            {/* Subtitle Section */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_title')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Image Privace */}
                                            <Row justify="center">
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", marginBottom: 20 }}>
                                                    <img
                                                        height={120}
                                                        src={`${AppConstants.front_base_url}/images/privacy.png`}
                                                        alt="Privacy Policy"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_subtitle')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_0a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_0b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_0b1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_0b2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_0b3')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 18 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1j')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1k')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1l')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>



                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1m')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1n')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_1o')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>




                                            {/* Second Question */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* list */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b4')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b5')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2b6')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d4')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d5')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d6')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2d7')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2e1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2e2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2e3')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2f1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_2f2')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_2i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Third question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_3a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_3b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_3c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_3d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_3e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_f5ield_3e3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e4')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e5')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e6')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_3e7')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Fourth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_4a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_4b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_4c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Fifth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_5a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_5c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_5c1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_5c2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_5c3')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* sixth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_6a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_6b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_6c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* list */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_6c1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_6c2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_6c3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_6c4')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* seventh question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_7a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_7b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_7c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* eighth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_8a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_8b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* list */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b4')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b5')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b6')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b7')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b8')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b9')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b10')
                                                            }}>
                                                            </li>

                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('privacy_field_8b11')
                                                            }}>
                                                            </li>

                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_8c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_8d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* ninth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_9a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_9b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* tenth question */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_10b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_10c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* tenth questuin */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: 500 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_11a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_11b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('privacy_field_11c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>



                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                        {/* ////////////////////////////////////////////Right Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                    </Row>


                </DefaultLayoutWebsite >
            </Col >
        </Row >
    );
}

export default Privacy;
