import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Drawer, Row, Col, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './DefaultLayoutWebsite.css';
import { AppConstants } from '../helpers/commons/constants';
import { navWebsite } from '../components/_nav-website';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;
const { Text } = Typography;


const DefaultLayoutWebsite = ({ children }) => {
    const { t } = useTranslation('website');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(['1']);


    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const menuItems = navWebsite;

    const onClickMenuItem = (e) => {
        if (menuItems?.length > 0) {
            const navItem = menuItems.find(item => item.key === e.key);

            if (typeof navItem !== 'undefined') {
                navigate(navItem.to)
                closeDrawer();
            }
        }

    };

    useEffect(() => {
        if (menuItems?.length > 0) {
            const currentPath = '/' + location.pathname.split('/')[1];
            const matchingItem = menuItems.find(item => item.to === currentPath);

            // Set the selected key if a matching item is found
            if (matchingItem) {
                setSelectedKey(matchingItem.key);
            }

        }
    }, [location.pathname, menuItems]);


    return (
        <Layout>
            <Header className="website-header">

                <img
                    height={31}
                    src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                />

                <div className="right-section">
                    <div>
                        <Button type="secondary" icon={<MenuOutlined />} onClick={showDrawer} />
                    </div>
                </div>

            </Header>

            <Drawer
                placement="left"
                onClose={closeDrawer}
                open={drawerVisible}
                closeIcon={false}
                width={300}
                styles={{
                    header: {
                        padding: 0,
                        background: 'linear-gradient(270deg, #ff5e78 15%, #fb3ac2 40%, #d709fa 60%, #9b0ef5 80%, #5606efff 100%)', // Change to your desired color
                    },
                    body: {
                        padding: 0,
                    },
                }}
                title={
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '44px'
                    }}>
                        <img src={`${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`} alt="Logo" style={{ marginLeft: 10, height: '28px' }} />
                        <Button
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={closeDrawer}
                            style={{ color: "#fff", marginRight: 10 }}
                        />
                    </div>
                }
            >
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                        <h2>Menu</h2>
                    </Col>
                </Row>
                <Menu
                    style={{ width: 300, padding: 0, margin: 0 }}
                    mode="vertical"
                    key={selectedKey}
                    defaultSelectedKeys={[selectedKey]}
                    items={menuItems}
                    onClick={onClickMenuItem}
                />
            </Drawer>


            <Content style={{ marginTop: 64, background: '#fff' }}>
                <div style={{ minHeight: 380 }}>
                    {children}
                </div>
            </Content>

            <Footer className='website-footer'>
                <Text style={{ fontSize: 12 }}>
                    <span dangerouslySetInnerHTML={{
                        __html: t('home_7-copyright-part1') + " " + (new Date().getFullYear()) + " " + t('home_7-copyright-part2')
                    }}

                    />
                </Text>
                <br />
                <Text style={{ fontSize: 12, textAlign: "center" }}>
                    <span dangerouslySetInnerHTML={{
                        __html: t('home_7-contact') + "&nbsp;&nbsp;&nbsp;" + t('home_7-terms') + "&nbsp;&nbsp;&nbsp;" + t('home_7-privacy')
                    }}
                    />
                </Text>
            </Footer>
        </Layout>
    );
}


export default DefaultLayoutWebsite
