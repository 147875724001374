import React, { useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
import { showErrorNotification, showSuccessNotification } from '../../../helpers/commons/notifications';
import DefaultLayoutWebsite from '../../../layout/DefaultLayoutWebsite';
import { wtFormatRoundedValue } from '../../../helpers/commons/utils';

const { Title, Text } = Typography;

const Unsubscribe = () => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [userSubscription, setUserSubscription] = useState(null);

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    // Handle input change
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Reset validation on input change
        if (!emailRegex.test(value)) {
            setIsValid(true);
            setErrorMessage('');
        }
    };

    const checkSubscription = async () => {
        if (email === '') {
            setIsValid(false);
            setErrorMessage(t('quiz-closing_6-qn_email_both_part-3'));
            return;
        }

        // Validate email
        if (emailRegex.test(email)) {
            setIsValid(true);
            setErrorMessage('');
        } else {
            setIsValid(false);
            setErrorMessage(t('quiz-closing_6-qn_email_both_part-3'));
            return;
        }

        await getSubscription(email);
    }


    const getSubscription = async (email) => {

        setIsLoading(true)
        wtApiCall(
            'get-subscription-from-email',
            {
                email: email
            },
            'get',
            (response) => {
                if (response && response.data) {
                    if (response.data) {
                        setUserSubscription(response.data)
                    }
                }
                setIsLoading(false)
            },
            (error) => {
                showErrorNotification(error.message)
                setIsLoading(false)
            },
            true
        )
    }


    const cancelSubscription = async (email) => {

        setIsLoading(true)
        wtApiCall(
            'cancel-subscription-from-email',
            {
                email: email
            },
            'post',
            (response) => {
                if (response && response.data) {
                    if (response.data) {
                        showSuccessNotification(t('unsubscribe-subscription-success-message'))
                        navigate('/')
                    }
                }
                setIsLoading(false)
            },
            (error) => {
                showErrorNotification(error.message)
                setIsLoading(false)
            },
            true
        )
    }

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            <Row justify="center" >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <DefaultLayoutWebsite>
                        <Row style={{ minHeight: "86vh" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                <Row justify="space-between" align="middle" className="header-row" style={{ marginBottom: 40 }}>
                                    <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                    <Col xs={22} sm={22} md={14} lg={14} xl={14} style={{ marginTop: 5, textAlign: "center" }}>
                                        <Title level={4} className='quiz-level-2-title'>
                                            {t('unsubscribe-subscription-title-1')}
                                        </Title>
                                    </Col>
                                    <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                </Row>
                                <Row justify="center" style={{ marginBottom: 20 }}>
                                    <Col xs={2} sm={2} md={8} lg={8} xl={8}></Col>
                                    <Col xs={20} sm={20} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                        <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                                            <input
                                                className='email-input'
                                                value={email}
                                                onChange={handleChange}
                                                placeholder={t('quiz-closing_6-qn_email_both_part-2')}
                                                style={{
                                                    borderColor: isValid ? 'black' : 'red', // Default border color and error color
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    outline: 'none',
                                                    width: "100%"
                                                }}
                                            />
                                            {/* Error message */}
                                            {!isValid && (
                                                <div className='email-validation'>
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={2} sm={2} md={8} lg={8} xl={8}></Col>
                                </Row>

                                <Row justify="center">
                                    <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                                    <Col xs={16} sm={16} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                        <Button type="primary" size="large" className="cta-button"
                                            style={{ maxWidth: 400 }}
                                            onClick={() => checkSubscription()}
                                        >
                                            {t('button_continue')}
                                        </Button>
                                    </Col>
                                    <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                                </Row>

                                {userSubscription !== null &&
                                    <>
                                        <Row align="middle" className="header-row" style={{ marginTop: 40 }}>
                                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                            <Col xs={22} sm={22} md={14} lg={14} xl={14} style={{ marginTop: 5, textAlign: "center" }}>
                                                <Text level={4} style={{ fontSize: 18, fontWeight: "bold" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: t('unsubscribe-subscription-title-2', { ns: 'website', subscriptionAmount: wtFormatRoundedValue((userSubscription.apply_discount ? userSubscription.discount : userSubscription.price), userSubscription.currency.currency_symbol, false, 2), duration: userSubscription.duration }) }} />
                                                </Text>
                                            </Col>
                                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                        </Row>
                                        <Row align="middle" className="header-row">
                                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                            <Col xs={22} sm={22} md={14} lg={14} xl={14} style={{ textAlign: "center" }}>
                                                <Text level={4} style={{ fontSize: 18, fontWeight: "bold" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: t('unsubscribe-subscription-status', { ns: 'website' }) }} />
                                                </Text>
                                            </Col>
                                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                                        </Row>

                                        <Row justify="center" style={{ marginBottom: 20 }}>
                                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                                            <Col xs={16} sm={16} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                                <Button type="primary" size="large" className="cancel-button"
                                                    style={{ maxWidth: 150 }}
                                                    onClick={() => cancelSubscription(email)}
                                                >
                                                    {t('button_cancel')}
                                                </Button>
                                            </Col>
                                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                    </DefaultLayoutWebsite >
                </Col>
            </Row >
        </React.Fragment >
    );
}

export default Unsubscribe;
