import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import { showErrorNotification } from '../../../helpers/commons/notifications';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
const { Title, Text } = Typography;

const Level105Quiz = ({ quiz }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
    }, [authTokenWebsite]);

    const [isLoading, setIsLoading] = useState(false);



    const level105Click = () => {

        setIsLoading(true)
        wtApiCall(
            'user/change-email-subscription',
            {},
            'post',
            (response) => {
                if (response && response.data) {
                    if (response.data) {

                        const newQuiz = { ...quiz, level: 106 };
                        wtSaveToLocalStorage('quiz', newQuiz);
                        wtSaveToLocalStorage('authUserWebsite', response.data.user);
                        wtSaveToLocalStorage('authTokenWebsite', response.data.token);
                        navigate('/email')
                    }
                    else {
                        showErrorNotification("Error in saving quiz data!");
                    }
                }
                setIsLoading(false)
            },
            (error) => {
                showErrorNotification(error.message)
                setIsLoading(false)
            },
            true
        )
    }

    const notGuidanceClick = () => {
        const newQuiz = { ...quiz, level: 106 };
        wtSaveToLocalStorage('quiz', newQuiz);
        navigate('/email')
    }

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {(authTokenWebsite !== '' && quiz !== null) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={18} xl={16} className="responsive-div">

                        {/* For Both single */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify="center" style={{ marginTop: 10 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Text className='quiz-level-2-title'>
                                        {t('quiz-closing_7-qn_confirm_both_part-1')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='quiz-level-2-title colored-title-standard'>
                                        {t('quiz-closing_7-qn_confirm_both_part-2')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 30 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='quiz-level-2-title'>
                                        {t('quiz-closing_7-qn_confirm_both_part-3')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 20 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", position: 'relative', height: "auto" }}>
                                    <img
                                        height={200}
                                        src={`${AppConstants.front_base_url}/images/treasure-chest.png`}
                                        alt="Treasure Chest"
                                    />
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 0, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", fontWeight: "500" }}>
                                    <Text>
                                        {t('quiz-closing_7-qn_confirm_both_part-4')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 30, marginTop: "25px !important" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 400 }}
                                        onClick={() => level105Click()}
                                    >
                                        {t('button_confirm')}
                                    </Button>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40, cursor: "pointer" }}
                                onClick={() => notGuidanceClick()}
                            >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 14, textDecoration: "underline" }}
                                        onClick={() => notGuidanceClick()}
                                    >
                                        {t('button_ialreadyknow')}
                                    </Text>
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>


            }
        </React.Fragment >
    );
}

export default Level105Quiz;
