import React from 'react'
import App from './App'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import 'leaflet/dist/leaflet.css';
import './i18n'; // import the i18n configuration
import TagManager from 'react-gtm-module';
import { AppConstants } from './helpers/commons/constants';

const tagManagerArgs = {
  gtmId: AppConstants.google_tag_manager_key // Replace with your GTM Container ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
