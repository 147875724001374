import React, { useEffect, useState } from 'react';
import { Modal, Button, List, Drawer, Row, Col, Menu } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Loading from '../helpers/commons/loader';
import { wtApiCall } from '../helpers/commons/ApiWrapper';
import { showErrorNotification } from '../helpers/commons/notifications';
import { useMediaQuery } from 'react-responsive';
import { AppConstants } from '../helpers/commons/constants';
import { wtSaveToLocalStorage } from '../helpers/commons/LocalStorage';

//get icon of language
const getIconComponent = (code) => {
    try {
        // Import SVG dynamically based on the language code
        const IconComponent = require(`../assets/flags/${code}.svg`);
        return IconComponent;
    } catch (error) {
        console.error(`Icon for ${code} not found`);
        return null;
    }
};

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const showModal = () => {
        if (isMobile) {
            setIsDrawerVisible(true);
        } else {
            setIsModalVisible(true);
        }
    };

    const handleCancel = () => {
        if (isMobile) {
            setIsDrawerVisible(false);
        } else {
            setIsModalVisible(false);
        }
    };

    const changeLanguage = (lng) => {
        wtSaveToLocalStorage('language', lng);
        i18n.changeLanguage(lng.code);
        setSelectedLanguage(lng.code);
        handleCancel();
    };

    const fetchLanguages = async () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'languages',
                {
                    _page_index: 1,
                    _page_size: 50,
                    _sort_order: { order: 'ascend', columnKey: 'sort_order' },
                    status: [1],
                },
                'get',
                (response) => {
                    if (response && response.data && response.data.data) {
                        if (response.data.data.length > 0) {
                            let languageArray = [];
                            for (let i = 0; i < response.data.data.length; i++) {
                                const language = response.data.data[i];
                                //import that icon
                                const languageObject = {
                                    id: language.id,
                                    code: language.code,
                                    name: language.name,
                                    checkout_type: language.checkout_type,
                                    icon: <img src={getIconComponent(language.code)} alt={language.name} style={{ width: 24, marginRight: 8, padding: 10 }} />
                                }
                                languageArray.push(languageObject);


                                //set selected language with default language
                                if (language.default === 1) {
                                    setSelectedLanguage(language.code);
                                    wtSaveToLocalStorage('language', language);
                                    i18n.changeLanguage(language.code);
                                }
                            }

                            setLanguages(languageArray);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
            )
        } catch (error) {
            showErrorNotification('Error fetching languages:', error);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (languages.length <= 0) {
            fetchLanguages();
        }
    }, [])

    //if loading true then return spinner
    if (isLoading) {
        return Loading;
    }

    const selectedLanguageObject = languages.find(lang => lang.code === selectedLanguage) || {};

    return (
        <>
            <Button type="secondary" onClick={showModal}
                style={{ display: 'flex', alignItems: 'left', paddingLeft: 0, paddingTop: 10 }}
            >
                <img src={(Object.keys(selectedLanguageObject).length > 0) ?
                    getIconComponent(selectedLanguageObject.code) : ''} alt={selectedLanguageObject.name}
                    style={{ width: 22, marginRight: 8 }}
                />

                <span style={{ fontSize: 14, display: 'flex', alignItems: 'left' }}>
                    {selectedLanguageObject.name}
                </span>

            </Button>


            {!isMobile ? (
                <Modal
                    className='language-modal'
                    title={
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '40px',
                            background: "linear-gradient(270deg, #ff5e78 15%, #fb3ac2 40%, #d709fa 60%, #9b0ef5 80%, #5606efff 100%)",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10
                        }}>
                            <img src={`${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`} alt="Logo" style={{ marginLeft: 10, height: '28px' }} />
                            <Button
                                type="text"
                                icon={<CloseOutlined />}
                                onClick={handleCancel}
                                style={{ color: "#fff", marginRight: 10 }}
                            />
                        </div>
                    }
                    open={isModalVisible}
                    closeIcon={false}
                    footer={null}
                    centered
                    style={{ maxWidth: '90vw' }}
                    styles={{ body: { padding: 20 } }}
                    width="70vw"
                >
                    <List
                        grid={{ gutter: 24, column: 4 }}
                        dataSource={languages}
                        renderItem={(item) => (
                            <List.Item
                                onClick={() => changeLanguage(item)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: item.code === selectedLanguage ? '#f0f0f0' : 'transparent'
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {item.icon}
                                    <span style={{ fontSize: 16 }}>{item.name}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                </Modal>
            ) : (
                <Drawer
                    placement="left"
                    onClose={handleCancel}
                    open={isDrawerVisible}
                    closeIcon={false}
                    width={300}
                    styles={{
                        header: {
                            padding: 0,
                            background: 'linear-gradient(270deg, #ff5e78 15%, #fb3ac2 40%, #d709fa 60%, #9b0ef5 80%, #5606efff 100%)', // Change to your desired color
                        },
                        body: {
                            padding: 0,
                        },
                    }}
                    title={
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '44px'
                        }}>
                            <img src={`${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`} alt="Logo" style={{ marginLeft: 10, height: '28px' }} />
                            <Button
                                type="text"
                                icon={<CloseOutlined />}
                                onClick={handleCancel}
                                style={{ color: "#fff", marginRight: 10 }}
                            />
                        </div>
                    }
                >
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                            <h3>Select Your Language</h3>
                        </Col>
                    </Row>

                    <List
                        grid={{ gutter: 24, column: 1 }} // Adjust for mobile view
                        dataSource={languages}
                        renderItem={(item) => (
                            <List.Item
                                onClick={() => changeLanguage(item)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: item.code === selectedLanguage ? '#f0f0f0' : 'transparent'
                                }}
                            >
                                <Row justify="center">
                                    <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                                        {item.icon}
                                        <span style={{ fontSize: 16 }}>{item.name}</span>
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                                        {item.code === selectedLanguage &&
                                            <span style={{ fontSize: 16, color: "#2D67AB" }}><CheckOutlined /></span>
                                        }
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </Drawer>
            )}
        </>
    );
};

export default LanguageSwitcher;
