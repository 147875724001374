import HttpHelper from "../../httpHelper";
import { AppConstants } from "../constants";
import { wtSaveToLocalStorage, wtGetFromLocalStorage } from "../LocalStorage";
import { currentDateTime } from "../moment";
import { showErrorNotification } from "../notifications";

export const wtApiCall = async (url, data, method, successCallback, failureCallback, isWebsiteCall = false, timeout = 90000, regardless = function () { }) => {

  let authToken = wtGetFromLocalStorage('authToken', '');
  let authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
  let remember = wtGetFromLocalStorage('remember', false);
  let idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  // Build headers to send
  const headers = {
    'Content-type': 'application/json',
  };

  // Set method if not set already
  if (!method) {
    method = 'get';
  }

  // Init data to send (if not already init) and attach basic values to it
  if (!data) {
    data = {};
  }

  // Special handling for laravel resources
  if ('get' === method || 'post' === method) {
    data._method = method;
  } else {
    // this goes for methods like patch, put, delete, etc.
    data._method = method;
    method = 'post';
  }

  //Send idle timeout and current time for verification of idle system
  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();
  data.is_website_call = isWebsiteCall;

  // Attach auth token if exists
  if (!isWebsiteCall) {
    if (authToken && authToken !== '') {
      headers.Authorization = 'Bearer ' + authToken;
    }
  }
  if (isWebsiteCall) {
    if (authTokenWebsite && authTokenWebsite !== '') {
      headers.Authorization = 'Bearer ' + authTokenWebsite;
    }
  }

  // Attach remember token if exists
  data.remember = remember;

  // Build settings for the call
  let settings = {
    url,
    headers,
    data,
    timeout: timeout
  };

  // Make the http request object
  const httpRequest = new HttpHelper();

  if (method === 'get') {
    settings['params'] = data;
  }
  else {
    settings['data'] = data;
  }

  // Set failure callback if not set already
  if (!failureCallback) {
    failureCallback = (result) => {
      showErrorNotification(result);
    };
  }

  // Call based on method
  if ('get' === method) {
    httpRequest.http_get(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'post') {
    httpRequest.http_post(
      settings,
      {
        401: () => doLogout(),
        419: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        413: (result) => failureCallback(JSON.parse(result)),
        422: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  } else if (method === 'put') {
    httpRequest.http_put(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

  else if (method === 'delete') {
    httpRequest.http_delete(
      settings,
      {
        401: () => doLogout(),
        200: (result) => successCallback(JSON.parse(result)),
        500: (result) => failureCallback(JSON.parse(result)),
        403: (result) => failureCallback(JSON.parse(result)),
      },
      regardless
    );
  }

};



export const wtParseApiCallFailureMessage = (result) => {
  if (typeof result === 'string') {
    return result;
  }

  let msg = '';

  if (result && result.message) {
    msg = result.message;
    if (result.data) {
      const keys = Object.keys(result.data);
      for (let key of keys) {
        msg += "\n    * " + result.data[key];
      }
    }
  }

  if ('' === msg) {
    if (result && result.exception && result.exception.indexOf('PostTooLargeException') > 0) {
      msg = 'The submitted payload exceeds the max allowed limit!';
    }
  }

  return msg;
};

export const doLogout = () => {
  // Dispatch logout to redux store

  wtSaveToLocalStorage('authUser', null);
  wtSaveToLocalStorage('authToken', '');
  wtSaveToLocalStorage('remember', false);
  wtSaveToLocalStorage('idle_timeout', '');
  wtSaveToLocalStorage('configurations', null);

  wtSaveToLocalStorage('authUserWebsite', null);
  wtSaveToLocalStorage('authTokenWebsite', '');
  wtSaveToLocalStorage('quiz', null);
  wtSaveToLocalStorage('quizDashboard', null);
  wtSaveToLocalStorage('country', null);
  wtSaveToLocalStorage('language', null);
  wtSaveToLocalStorage('authUserUpsell', null);
  wtSaveToLocalStorage('authUserSubscription', null);


  // Invalidate the display
  window.location.reload();
};

export const lzIsArray = (input) => {
  return Array.isArray(input);
};