import React, { useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';
import { wtFormatRoundedValue } from '../../../helpers/commons/utils';

const { Title, Text } = Typography;

const Level111Quiz = ({ quiz, authUserWebsite, currency, expertConsultationOffer, getMyCopy, noThankyou }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const [isDesktop, setIsDesktop] = React.useState(window.innerWidth >= 1024);
    const [isSmallMobile, setSmallMobile] = React.useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
            setSmallMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 111) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 111 && expertConsultationOffer.length > 0) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">
                        <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                            <Col xs={4} sm={4} md={7} lg={7} xl={7}></Col>
                            <Col xs={16} sm={16} md={10} lg={10} xl={10} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                    {(+authUserWebsite.language.checkout_type === 1) &&
                                        <span dangerouslySetInnerHTML={{
                                            __html: t('upsell_5-consult_title')
                                        }} />
                                    }
                                    {(+authUserWebsite.language.checkout_type === 0) &&
                                        <span dangerouslySetInnerHTML={{
                                            __html: t('upsell_5-flow-2_loveguide-title')
                                        }} />
                                    }
                                </Text>
                            </Col>
                            <Col xs={4} sm={4} md={7} lg={7} xl={7}></Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 20, fontSize: 14 }}>
                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                            <Col xs={22} sm={22} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                {(+authUserWebsite.language.checkout_type === 1) &&
                                    <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                        {t('upsell_5-consult_subtitle')}
                                    </Text>
                                }
                                {(+authUserWebsite.language.checkout_type === 0) &&
                                    <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                        {t('upsell_5-flow-2_loveguide-subtitle')}
                                    </Text>
                                }
                            </Col>
                            <Col xs={1} sm={1} md={5} lg={5} xl={5}></Col>
                        </Row>

                        {(+authUserWebsite.language.checkout_type === 1) &&
                            <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                                <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                        {t('upsell_5-consult_body-1')}
                                    </Text>

                                </Col>
                                <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
                            </Row>
                        }
                        {(+authUserWebsite.language.checkout_type === 0) &&
                            <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                                <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                    <ul style={{ fontSize: 14, fontWeight: "400", paddingInlineStart: isDesktop ? 40 : 30 }}>
                                        <li style={{ marginBottom: 15 }}>{t('upsell_5-flow-2_loveguide-benefit-1')}</li>
                                        <li style={{ marginBottom: 15 }}>{t('upsell_5-flow-2_loveguide-benefit-2')}</li>
                                        <li>{t('upsell_5-flow-2_loveguide-benefit-3')}</li>
                                    </ul>
                                </Col>
                                <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                            </Row>
                        }


                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Row justify="center" style={{ marginBottom: 20 }}>
                                    <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>

                                    <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                        <div className="special-offer-thumbnail-container" style={{ width: isSmallMobile ? 280 : 350 }}>
                                            <div className="special-offer-thumbnail-content">
                                                <Row justify="center" style={{ marginBottom: 20 }}>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                        {(+authUserWebsite.language.checkout_type === 1) &&
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title') + " "}
                                                                {wtFormatRoundedValue(expertConsultationOffer[0].discount, currency, false, 2)}
                                                            </Text>
                                                        }
                                                        {(+authUserWebsite.language.checkout_type === 0) &&
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title') + " "}
                                                                {wtFormatRoundedValue(expertConsultationOffer[0].discount_checkout_2, currency, false, 2)}
                                                            </Text>
                                                        }
                                                    </Col>
                                                </Row>

                                                <Row justify="center">
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {t('upsell_5-consult_body-2')}
                                                        </Text>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ textAlign: "right" }}>
                                                        {(+authUserWebsite.language.checkout_type === 1) &&
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {wtFormatRoundedValue(expertConsultationOffer[0].discount, currency, false, 2)}
                                                            </Text>
                                                        }
                                                        {(+authUserWebsite.language.checkout_type === 0) &&
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {wtFormatRoundedValue(expertConsultationOffer[0].discount_checkout_2, currency, false, 2)}
                                                            </Text>
                                                        }
                                                    </Col>
                                                </Row>

                                                <hr style={{ width: "100%" }} />

                                                <Row justify="center">
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ textAlign: "left" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {t('upsell_5-consult_body-3')}
                                                        </Text>
                                                    </Col>

                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right" }}>
                                                        <Text className='upsell-colored-title'>
                                                            {(+authUserWebsite.language.checkout_type === 1) &&
                                                                <span dangerouslySetInnerHTML={{
                                                                    __html: t('upsell_5-consult_body-3-1', {
                                                                        priceDiscountConsult: wtFormatRoundedValue(expertConsultationOffer[0]?.discount_rate, '', false, 2),
                                                                    })
                                                                }} />
                                                            }
                                                            {(+authUserWebsite.language.checkout_type === 0) &&
                                                                <span dangerouslySetInnerHTML={{
                                                                    __html: t('upsell_5-consult_body-3-1', {
                                                                        priceDiscountConsult: wtFormatRoundedValue(expertConsultationOffer[0]?.discount_rate_checkout_2, '', false, 2),
                                                                    })
                                                                }} />
                                                            }
                                                        </Text>
                                                    </Col>

                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {(+authUserWebsite.language.checkout_type === 1) &&
                                                                <span dangerouslySetInnerHTML={{
                                                                    __html: t('upsell_5-consult_body-3-2', {
                                                                        priceConsultReg: wtFormatRoundedValue(expertConsultationOffer[0]?.price, currency, false, 2),
                                                                    })
                                                                }} />
                                                            }
                                                            {(+authUserWebsite.language.checkout_type === 0) &&
                                                                <span dangerouslySetInnerHTML={{
                                                                    __html: t('upsell_5-consult_body-3-2', {
                                                                        priceConsultReg: wtFormatRoundedValue(expertConsultationOffer[0]?.price_checkout_2, currency, false, 2),
                                                                    })
                                                                }} />
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>
                                </Row>


                            </Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Button type="primary" size="large" className="cta-button"
                                    style={{ maxWidth: isDesktop ? 300 : 280 }}
                                    onClick={() => getMyCopy()}
                                >
                                    {(+authUserWebsite.language.checkout_type === 0) &&
                                        <>
                                            {t('button_getmyguide')}
                                        </>
                                    }
                                    {(+authUserWebsite.language.checkout_type === 1) &&
                                        <>
                                            {t('upsell_5-consult_action')}
                                        </>
                                    }
                                </Button>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 20, fontSize: 14 }}>
                            <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 10, fontWeight: "500" }}>
                                    {t('upsell_5-consult_body-4')}
                                </Text>
                            </Col>
                            <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 40, fontSize: 14, cursor: "pointer" }}
                            onClick={() => noThankyou()}
                        >
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 10, textDecoration: "underline", fontWeight: "500" }}
                                    onClick={() => noThankyou()}
                                >
                                    {t('upsell_3-signup_skip')}
                                </Text>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level111Quiz;
