import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import DefaultLayoutWebsite from '../../../layout/DefaultLayoutWebsite';

const { Text } = Typography;

const Refund = () => {
    const { t } = useTranslation('legal');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DefaultLayoutWebsite>

                    <Row justify="center">

                        {/* ////////////////////////////////////////////Left Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                        {/* ////////////////////////////////////////////Centered Content///////////////////////////////////////////////////////// */}
                        <Col xs={22} sm={22} md={18} lg={16} xl={14} >


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                                    <Row justify="center" style={{ marginTop: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>


                                            {/* Subtitle Section */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_title')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 1 */}

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>



                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ol>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1d1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1d2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1d3')
                                                            }}>
                                                            </li>
                                                        </ol>
                                                    </Text>
                                                </Col>
                                            </Row>



                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* List */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <ul>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1e1')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1e2')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1e3')
                                                            }}>
                                                            </li>
                                                            <li dangerouslySetInnerHTML={{
                                                                __html: t('refund_field_1e4')
                                                            }}>
                                                            </li>
                                                        </ul>
                                                    </Text>
                                                </Col>
                                            </Row>




                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_1e5')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 2 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_2g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 3 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_3a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_3b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Section 4 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 28 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4a')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4b')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4c')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4d')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4e')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4f')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4g')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4h')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4i')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" style={{ marginBottom: 60 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('refund_field_4j')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>


                                </Col>
                            </Row>
                        </Col>


                        {/* ////////////////////////////////////////////Right Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                    </Row>


                </DefaultLayoutWebsite >
            </Col >
        </Row >
    );
}

export default Refund;
