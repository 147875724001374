import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import DefaultLayoutWebsite from '../../../layout/DefaultLayoutWebsite';
import { wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';

const { Text } = Typography;


const Contact = () => {
    const { t } = useTranslation('legal');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DefaultLayoutWebsite>

                    <Row justify="center" style={{ height: "80vh" }}>

                        {/* ////////////////////////////////////////////Left Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                        {/* ////////////////////////////////////////////Centered Content///////////////////////////////////////////////////////// */}
                        <Col xs={22} sm={22} md={18} lg={16} xl={14} >


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                                    <Row justify="center" style={{ marginTop: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>


                                            {/* Subtitle Section */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('contact_title')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>

                                            {/* Section 1 */}
                                            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 18 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('contact_email')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                                    <Text style={{ fontSize: 16 }}>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: t('contact_text')
                                                        }}
                                                        />
                                                    </Text>
                                                </Col>
                                            </Row>


                                            {/* Button Section */}
                                            {/* <Row justify="center" align="middle" style={{ marginBottom: isDesktop ? 60 : 40 }}>
                                                <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                                    <Button type="primary" size="large" className="home-cta-button" onClick={() => startQuiz()}>
                                                        {t('button_takethequiz')}
                                                    </Button>
                                                </Col>
                                            </Row> */}

                                        </Col>
                                    </Row>


                                </Col>
                            </Row>
                        </Col>


                        {/* ////////////////////////////////////////////Right Space///////////////////////////////////////////////////////// */}
                        <Col xs={1} sm={1} md={3} lg={4} xl={5}></Col>


                    </Row>


                </DefaultLayoutWebsite >
            </Col >
        </Row >
    );
}

export default Contact;
