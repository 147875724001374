import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { AppConstants } from '../../../helpers/commons/constants';


const { Text } = Typography;

const PaymentFailModal = ({ setShowPaymentFailModal, showPaymentFailModal, paymentFailModalTitleKey, paymentFailModalTextKey }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    
    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Modal
            className='special-offer-modal-parent'
            wrapClassName='special-offer-modal'
            width={350}
            closable={false}
            onCancel={() => setShowPaymentFailModal(false)}
            open={showPaymentFailModal}
            footer={[
                <Row key="footer-row" justify="center" style={{ marginBottom: 10 }}>
                    <Col key="footer-col-2" xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                        <Button type="primary" size="large" className="cta-button"
                            style={{ margin: 0, fontSize: 12, height: 34 }}
                            onClick={() => setShowPaymentFailModal(false)}
                        >
                            {t('button_ok')}
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Row justify="center" style={{ marginBottom: 10, marginTop: -10 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                    <img
                        height={28}
                        src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                        alt="Logo"
                        className="logo"
                    />
                </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                    <Text style={{ fontSize: 24, fontWeight: "bold" }}>
                        {`${t(paymentFailModalTitleKey)}`}
                    </Text>
                </Col>
            </Row>

            <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                    <Text style={{ fontSize: 18, fontWeight: "500" }}>
                        <span dangerouslySetInnerHTML={{ __html: t(paymentFailModalTextKey) }} />
                    </Text>
                </Col>
            </Row>
        </Modal>
    );
}

export default PaymentFailModal;
