import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { getPlaceName, getPlaceNameFromExternalResource, getUserTimeZoneOffset } from '../../../helpers/commons/utils';
import { showErrorNotification } from '../../../helpers/commons/notifications';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
import i18next from 'i18next';
const { Title, Text } = Typography;

const Level104Quiz = ({ quiz }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const country = wtGetFromLocalStorage('country', null);
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
    }, [authTokenWebsite]);

    const [isLoading, setIsLoading] = useState(false);



    //Get country from ip address
    useEffect(() => {
        if (country === null) {
            fetchLocation();
        }
    }, []);


    const fetchLocation = async () => {
        setIsLoading(true);
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionStatus.state === 'granted') {
            navigator.geolocation.getCurrentPosition(
                async (pos) => {
                    const { latitude, longitude } = pos.coords;
                    try {
                        const locationData = await getPlaceName(latitude, longitude);
                        //Get country code
                        const countryCode = locationData.address.country_code;
                        await getCountryFromCode(countryCode);
                    } catch (error) {
                        console.error('Error fetching place name:', error);
                        await fallbackToExternalResource();
                    }

                    setIsLoading(false);
                },
                async (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        console.warn('User denied the request for Geolocation.');
                    } else {
                        console.error('Error getting the location:', error.message);
                    }
                    await fallbackToExternalResource();
                    setIsLoading(false);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            await fallbackToExternalResource();
            setIsLoading(false);
        }
    };


    const fallbackToExternalResource = async () => {
        try {
            const locationData = await getPlaceNameFromExternalResource();
            if (typeof locationData === 'object' && locationData !== null && !Array.isArray(locationData)) {

                //Get country code
                const countryCode = locationData.country_code;

                await getCountryFromCode(countryCode);
            }
            else {
                //If everything fails just fetch us country details
                await getCountryFromCode('US');
                setIsLoading(false);
                console.error('Error fetching location from external resource');
            }
        } catch (error) {

            //If everything fails just fetch us country details
            await getCountryFromCode('US');
            setIsLoading(false);
            console.error('Error fetching location from external resource:', error);
        }
    };


    const getCountryFromCode = async (countryCode) => {
        wtApiCall(
            'country/get-country-from-code',
            {
                country_code: countryCode
            },
            'post',
            (response) => {
                if (response && response.data) {
                    wtSaveToLocalStorage('country', response.data);
                }
            },
            (error) => {
                showErrorNotification(error.message)
            },
            true
        )
    }


    const saveQuizInDb = async (email) => {
        const country = wtGetFromLocalStorage('country', null);
        const currentLanguage = wtGetFromLocalStorage('language', null);

        const timezone = getUserTimeZoneOffset();

        setIsLoading(true)
        wtApiCall(
            'user-quiz',
            {
                quiz: quiz,
                email: email,
                country: country,
                language: currentLanguage,
                timezone: timezone
            },
            'post',
            (response) => {
                if (response && response.data) {
                    if (response.data) {

                        const newQuiz = { ...quiz, level: 105, email: email };
                        wtSaveToLocalStorage('quiz', newQuiz);
                        wtSaveToLocalStorage('authUserWebsite', response.data.user);
                        wtSaveToLocalStorage('authTokenWebsite', response.data.token);
                        navigate('/email')
                    }
                    else {
                        showErrorNotification("Error in saving quiz data!");
                    }
                }
                setIsLoading(false)
            },
            (error) => {
                showErrorNotification(error.message)
                setIsLoading(false)
            },
            true
        )
    }

    // Handle input change
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Reset validation on input change
        if (!emailRegex.test(value)) {
            setIsValid(true);
            setErrorMessage('');
        }
    };

    const level104Click = async () => {
        if (email === '') {
            setIsValid(false);
            setErrorMessage(t('quiz-closing_6-qn_email_both_part-3'));
            return;
        }

        // Validate email
        if (emailRegex.test(email)) {
            setIsValid(true);
            setErrorMessage('');
        } else {
            setIsValid(false);
            setErrorMessage(t('quiz-closing_6-qn_email_both_part-3'));
            return;
        }

        await saveQuizInDb(email);
    }



    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {(authTokenWebsite !== '' && quiz !== null) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">

                        {/* For Both single */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Title level={4} className='quiz-level-2-title'>
                                        {t('quiz-closing_6-qn_email_both_part-1')}
                                    </Title>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ textAlign: "center" }}>
                                    <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                                        <input
                                            className='email-input'
                                            value={email}
                                            onChange={handleChange}
                                            placeholder={t('quiz-closing_6-qn_email_both_part-2')}
                                            style={{
                                                borderColor: isValid ? 'black' : 'red', // Default border color and error color
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                outline: 'none',
                                                width: "100%"
                                            }}
                                        />
                                        {/* Error message */}
                                        {!isValid && (
                                            <div className='email-validation'>
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={20} sm={20} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                    <Text>
                                        {t('quiz-closing_6-qn_email_both_part-4')}
                                        {t('quiz-closing_6-qn_email_both_part-5')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>
                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={20} sm={20} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                    <Text>
                                        {t('quiz-closing_6-qn_email_both_part-6')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={20} sm={20} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                    <Text>
                                        <span dangerouslySetInnerHTML={{ __html: t('quiz-closing_6-qn_email_both_part-7') }} />
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>



                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 400 }}
                                        onClick={() => level104Click()}
                                    >
                                        {t('button_continue')}
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>


            }
        </React.Fragment >
    );
}

export default Level104Quiz;
